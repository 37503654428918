import React, { useEffect, useState, useRef } from 'react'
import * as R from 'ramda'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'

import { useGetStyng } from 'utils/useGetStyng'
import { handleStyngUpload } from 'utils/handleStyngUpload'
import useStores from '../../common/hook/useStore'

import { Modal, Box } from '@mui/material'

import Spinner from '../../ui/Spinner/Spinner'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import SearchBar from '../../ui/SearchBar/SearchBar'
import TextField from '../../ui/TextField/TextField'
import ImageUploadField from '../../ui/ImageUploadField/ImageUploadField'

import Text from '../../locale/strings'

import API from '../../common/api'
import { handleTypeNumber } from 'common/utils'
import TrackItem from '../CreateStyng/TrackItem/Trackitem'
import CreateStyngStore from '../CreateStyng/CreateStyngStore'
import { useStyngQuery } from '../../styngs/CreateStyng/CreateStyngStore'
import { EditStyngRequest, EditStyngResponse } from '../../common/api/styng/styng'
import { ImageEntity, ImageUrl } from '../../common/api/image/image'
import { Track } from '../../common/api/track/track'
import { defaultImg, FileType } from '../../common/constants'
import useImage from '../../common/hook/useImage'
import LsrTracksFilter from '../CreateStyng/LsrTracksFilter'

import styles from './styngModal.module.scss'

const schema = yup.object({
  title: yup.string().max(50, 'Title must be at most 50 characters').required('Title is required'),
  genre: yup.string().required('Genre is required'),
})

interface StyngFormValues {
  title: string
  price: ''
  genre: string
  imageId: Nullable<string>
}

const initialValues: StyngFormValues = {
  title: '',
  price: '',
  genre: '',
  imageId: null,
}

interface StyngProps {
  editStyngStore: CreateStyngStore
  styngId: string
  open: boolean
  handleClose: () => void
  refetch?: any
}

const StyngModal = ({ editStyngStore, open, styngId, handleClose, refetch }: StyngProps) => {
  const { notificationsStore } = useStores()
  const [renderTracks, setRenderTracks] = useState<boolean>(false)
  const [showActiveTrack, setShowActiveTrack] = useState<boolean>(false)
  const [styngError, setStyngError] = useState<boolean>(false)
  const [styngFormatError, setStyngFormatError] = useState<boolean>(false)
  const [filter, setFilter] = useState('')
  const [url, setUrl] = useState('')
  const [lsrStyng, setLsrStyng] = useState<Nullable<File>>(null)
  const queryClient = useQueryClient()
  const { data: initialStyng } = useStyngQuery(styngId)
  const audioRef = useRef<any>(null)

  const addNewSting = styngId.length === 0

  const mutationLstStyng = useMutation<EditStyngResponse, Error, EditStyngRequest>(
    (body: EditStyngRequest) => {
      if (!R.isNil(initialStyng)) {
        return API.styngs.put({ ...body, id: initialStyng.id })
      }

      return API.styngs.create(body)
    },
    {
      onSuccess: () => {
        refetch()
        editStyngStore.setTrack(null)
        handleClose()
        queryClient.invalidateQueries('styngs')

        !R.isNil(initialStyng)
          ? notificationsStore.successNotification('Styng successfully edited')
          : notificationsStore.successNotification('Styng successfully added')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const hangleSetImage = (imageId: string) => {
    formik.setFieldValue('imageId', imageId)
  }

  const mutationImageUrl = useMutation<ImageEntity, Error, ImageUrl>((body: ImageUrl) => {
    return API.image.postUrl(body)
  })

  const handleSubmitLsrStyng = async (values: StyngFormValues) => {
    if (R.isNil(lsrStyng)) {
      setStyngError(true)
    } else {
      setStyngError(false)
    }

    const sendForm = (imageId: Nullable<string>) => {
      let uploadId: string
      let uploadUrl: string

      if (R.isNil(lsrStyng)) {
        mutationLstStyng.mutate({
          title: values.title.trim(),
          price: values.price,
          genre: values.genre.trim(),
          trackId: editStyngStore?.currentTrack?.trackId ?? initialStyng?.trackId,
          imageId: imageId,
          fileId: '',
        })
      } else {
        const getDataForStyngUpload = API.general.getUploadData({
          fileContentType: FileType.STYNG,
        })

        getDataForStyngUpload
          .then((response) => {
            uploadId = response.id
            uploadUrl = response.uploadUrl

            return fetch(uploadUrl, {
              method: 'PUT',
              body: lsrStyng,
            })
          })
          .then(() => {
            mutationLstStyng.mutate({
              title: values.title.trim(),
              price: values.price,
              genre: values.genre.trim(),
              trackId: editStyngStore?.currentTrack?.trackId ?? initialStyng?.trackId,
              imageId: imageId,
              fileId: uploadId,
            })
          })
      }
    }

    const isFormValid = (!R.isNil(editStyngStore.currentTrack) && !R.isNil(lsrStyng)) || !R.isNil(initialStyng)

    if (isFormValid) {
      if (!R.isNil(values.imageId)) {
        sendForm(values.imageId)

        return
      }

      if (editStyngStore.currentTrack?.imageUrl) {
        try {
          await mutationImageUrl.mutate(
            { url: editStyngStore.currentTrack.imageUrl },
            {
              onSuccess: (data) => {
                sendForm(data.id)
              },
            },
          )
        } catch (error: any) {
          notificationsStore.errorNotification(error)
        }
      }
    }
  }

  useEffect(() => {
    if (!R.isNil(lsrStyng)) {
      const url = URL.createObjectURL(lsrStyng)

      setUrl(url)

      if (audioRef.current) {
        audioRef.current.load()
      }
    }
  }, [lsrStyng])

  const mutationGetStyng = useGetStyng((data: any) => {
    setUrl(data)
  })

  const formik = useFormik<StyngFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmitLsrStyng,
  })

  const { values, resetForm, setFieldValue } = formik
  const { data } = useImage(values.imageId || '')
  const imageStyng = data?.url ?? editStyngStore?.currentTrack?.imageUrl ?? defaultImg

  useEffect(() => {
    setFieldValue('genre', editStyngStore.currentTrack?.genre ?? '')
    setFieldValue('imageId', null)
  }, [editStyngStore.currentTrack, setFieldValue])

  useEffect(() => {
    if (!R.isNil(initialStyng)) {
      resetForm({
        values: {
          title: initialStyng.name,
          price: initialStyng.price,
          genre: initialStyng.genres[0]?.name,
          imageId: initialStyng.imageId,
        },
      })
    }
  }, [initialStyng, resetForm])

  useEffect(() => {
    if (styngId !== '') {
      mutationGetStyng.mutate({ id: styngId })
    }
  }, [])

  const handleClickTrack = (track: Track) => {
    editStyngStore.setTrack(track)
    editStyngStore.search('')
    setFilter('')
    setRenderTracks(false)

    if (!R.isEmpty(editStyngStore.currentTrack)) {
      setShowActiveTrack(true)
    } else {
      setShowActiveTrack(false)
    }

    setTimeout(() => formik.validateField('genre'))
  }

  useEffect(() => {
    if (editStyngStore.currentTrack !== null) {
      setShowActiveTrack(true)
      setFieldValue('genre', editStyngStore.currentTrack?.genre ?? '')
      setFieldValue('imageId', null)
    }
  }, [editStyngStore.currentTrack, setFieldValue])

  const handleSearch = (value: string) => {
    editStyngStore.search(value)

    if (!R.isEmpty(editStyngStore.filter)) {
      setRenderTracks(true)
    } else {
      setRenderTracks(false)
    }
  }

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box>
        {styngId && R.isNil(initialStyng) ? (
          <Spinner />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>{addNewSting ? 'Add styng' : 'Edit styng'}</div>
            <div className={styles.innerContainer}>
              {!addNewSting && (
                <div className={styles.searchWrapper}>
                  <SearchBar
                    modalView
                    label={Text.page.tracks.searchPlaceholder}
                    filter={filter}
                    onChangeFilter={setFilter}
                    onSearch={handleSearch}
                  >
                    <If condition={renderTracks}>
                      <LsrTracksFilter editStyngStore={editStyngStore} onClickTrack={handleClickTrack} />
                    </If>
                  </SearchBar>
                </div>
              )}
              <div className={styles.form}>
                <If condition={showActiveTrack && open}>
                  <TrackItem active track={editStyngStore.currentTrack!} onClick={handleClickTrack} />
                </If>
                <div className={styles.uploadCover}>
                  <label htmlFor="file-styng-id">
                    <input
                      hidden
                      id="file-styng-id"
                      type="file"
                      accept="audio/aac, audio/mp3"
                      value=""
                      onChange={(event) => handleStyngUpload(event, setStyngError, setStyngFormatError, setLsrStyng)}
                    />
                    <Button isUpload data-test="upload-styng-button">
                      {Text.page.styngs.create.uploadStyng}
                    </Button>
                    <If condition={styngError && R.isNil(initialStyng)}>
                      <span className={styles.customError}>{Text.page.styngs.styngError}</span>
                    </If>
                    <If condition={styngFormatError}>
                      <span className={styles.customError}>{Text.page.styngs.supportedFiles}</span>
                    </If>
                  </label>
                  <If condition={!R.isNil(lsrStyng)}>
                    <span className={styles.fileName}>{lsrStyng?.name}</span>
                  </If>
                </div>
                {url && (
                  <audio controls controlsList="nodownload" ref={audioRef} className={styles.audioPreview}>
                    <source src={url} />
                  </audio>
                )}
                <TextField
                  fullWidth
                  data-test="title-field"
                  label={Text.page.styngs.create.fields.title + ' *'}
                  name="title"
                  value={values.title}
                  error={formik.errors.title}
                  inputProps={{ maxLength: 50 }}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  type="number"
                  data-test="price-field"
                  label={Text.page.styngs.create.fields.price}
                  name="price"
                  value={values.price}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                  onKeyPress={(e) => handleTypeNumber(e)}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  data-test="genre-field"
                  label={Text.page.styngs.create.fields.genre}
                  name="genre"
                  value={values.genre}
                  disabled={true}
                  className={styles.input}
                  error={formik.errors.genre}
                  onChange={formik.handleChange}
                />
                <div className={styles.uploadImage}>
                  <ImageUploadField image={imageStyng} hangleSetImage={hangleSetImage} />
                </div>
              </div>
            </div>

            <div className={styles.submitContainer}>
              <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleClose}>
                {Text.common.cancel}
              </Button>
              <Button
                data-test="submit-button"
                disabled={!(formik.isValid && formik.dirty) && lsrStyng === null}
                loading={mutationLstStyng.isLoading}
                color={!(formik.isValid && formik.dirty) && lsrStyng === null ? ButtonColor.INFO : ButtonColor.PRIMARY}
                onClick={formik.handleSubmit}
              >
                {Text.common.save}
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default StyngModal
